<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="Box flex">
      <!-- 左侧tabbar -->
      <div class="tabbar">
        <div class="text-cut" v-for="(item, i) in machineTypeList"
             :key="item.id"
             @click="handleTab(i)"
             :class="isActive == i ? 'bgwhite' : ''">
          {{ item.typeName }}
        </div>
      </div>
      <!-- 右侧列表 -->
      <div class="dataList bbox pl20 pr10 flex1 pt25 pb25">
        <div class="item flex align-center"
             v-for="item in machineChildrens"
             :key="item.typeCode"
             @click="gotoDetail(item.typeCode, item.typeName)">
          <img :src="item.logo"
               alt=""
               width="62"
               height="62" />
          <div class="ml10 texts-cut2 flex1">
            {{ item.typeName }}
          </div>
          <i class="el-icon-arrow-right ml10"></i>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/commonHead.vue";
import Footer from "../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      machineTypeList: [],
      isMore: "0",
      isMsg: "0",
      machineChildrens: [],
      from: "",
      tabbarList: [],
      isActive: -1,
      key: "",
    };
  },
  watch: {
    machineTypeList () {
      if (this.machineTypeList.length > 0) {
        if (this.isActive != -1 && this.isActive != undefined) {
          this.handleTab(this.isActive);
        } else {
          this.handleTab(0);
        }
      }
    },
  },
  methods: {
    // tabbar切换
    handleTab (i) {
      this.isActive = i;
      this.machineChildrens = this.machineTypeList[i].children;
    },
    gotoDetail (typeCode, typeName) {
      var pushData = {};
      var query = {};
      if (this.from == "资料下载") {
        this.$router.push({
          path: "/upload",
          query: {
            typeCode: typeCode,
            typeName: typeName,
          },
        });
      } else if (this.from == "培训资料") {
        this.$router.push({
          path: "/informationDetail",
          query: {
            typeCode: typeCode,
            typeName: typeName,
            key: this.key,
          },
        });
      } else if (this.from == "培训测试") {
        var sendData = {};
        sendData.typeCode = typeCode;
        sendData.type = "1";
        var that = this;
        this.$http
          .post("exam/queryExamByTypeCode", sendData)
          .then(function (res) {
            // debugger
             console.log('mode--res.data==',res.data);
            if (200 == res.data.code && res.data.data != undefined) {
              var pushData = {};
              pushData.path = "/testDetail";
              var query = {};
              query.title = res.data.data.title;
              query.examCode = res.data.data.examCode;
              query.content = res.data.data.content;
              query.time = res.data.data.time;
              pushData.query = query;
              that.$router.push(pushData);
            } else {
              that.$notify.info({
                title: "提示",
                message: "当前机型无培训试题，请联系管理管添加培训试卷！",
                showClose: true,
              });
            }
          });
      } else {
        var sendData = {};
        sendData.typeCode = typeCode;
        sendData.memberCode = localStorage.getItem("memberCode");
        var that = this;
        this.$http
          .post("memberMachineType/queryMachineStatus", sendData)
          .then(function (res) {
            if (200 == res.data.code) {
              if ("1" == res.data.data) {
                that.$router.push({
                  path: "/informationDetail",
                  query: {
                    typeCode: typeCode,
                    typeName: typeName,
                    key: that.key,
                  },
                });
              } else {
                that.$notify.error({
                  title: "提示",
                  message: "查询资料失败，请先通过当前机型的考试",
                  showClose: true,
                });
              }
            }
          });
      }
    },
    loadMachineTypeList (memberCode) {
      var sendData = {};
      sendData.memberCode = memberCode;
      if (this.key != undefined && this.key.length > 0) {
        sendData.key = this.key;
        var that = this;
        this.$http
          .post("machineType/queryMachineTypeListWithTypeName", sendData)
          .then(function (res) {
            if (200 == res.data.code) {
              that.machineTypeList = res.data.data;
            }
          });
      } else {
        var that = this;
        this.$http
          .post("machineType/queryHomePageMachineTypeList", sendData)
          .then(function (res) {
            if (200 == res.data.code) {
              that.machineTypeList = res.data.data.returnData;
            }
          });
      }
    },
  },
  created () {
    this.isActive = this.$route.query.i;
    this.from = this.$route.query.from;
    if ("资料下载" != this.from) {
      this.key = this.$route.query.key;
    }
    var memberCode = localStorage.getItem("memberCode");
    /**
     * 加载机型信息
     */
    this.loadMachineTypeList(memberCode);
  },
};
</script>

<style scoped>
.Box {
  height: calc(100vh - 120px);
  overflow: hidden;
  padding-top: 50px;
}
.tabbar {
  width: 24%;
  background: #eeeeee;
  overflow-y: auto;
  text-align: center;
}
.tabbar > div {
  height: 46px;
  line-height: 46px;
}
.dataList {
  overflow-y: auto;
}
.item {
  margin-bottom: 20px;
}
</style>